import React, { useEffect } from 'react';
import '../styles/DownloadPage.css';
import DownloadBanner from '../components/DownloadBanner'
import DownloadDescription from '../components/DownloadDescription'
import DownloadResources from '../components/DownloadResources'
import DownloadUnknownSources from '../components/DownloadUnknownSources'
function DownloadPage() {

  useEffect(() => {
    document.title = "Download - Musga Player"
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className="download-page">
      <DownloadBanner/>
      <DownloadDescription/>
      <DownloadResources/>
      <DownloadUnknownSources/>
     
    </div>
  );
}

export default DownloadPage;
