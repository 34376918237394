import React, { useEffect } from 'react';
import Banner from '../components/Banner';
import Description from '../components/Description';
import AppCarousel from '../components/AppCarousel';
import Resources from '../components/Resources'
import '../styles/HomePage.css';

function HomePage() {

  useEffect(() => {
    document.title = "Musga Player"
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className="homepage">
      <Banner />
      <Description/>
      <AppCarousel/>
      <Resources/>
    </div>
  );
}

export default HomePage;
