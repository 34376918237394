import React, { useState } from 'react';

import '../styles/DownloadUnknownSources.css';

const DownloadUnknownSources = () => {

  const [showSteps, setShowSteps] = useState(false);

  const toggleSteps = () => {
    setShowSteps(!showSteps);
  };

  return (
    <div className="download-unknown-source-description">
      <div className="download-unknown-source-description-left">
        <h1>Habilitando fontes desconhecidas no Android</h1>
        <p>
          Por segurança, o Android não permite a instalação direta do APK. Com isso, podem surgir diversos avisos de arquivo ou apps inseguros. Não se preocupe!
        </p>
        <p>
          Nosso app é assinado digitalmente e livre de vírus. Siga o passo a passo para permitir a instalação e poder desfrutar do app.
        </p>
        <h3>Links Úteis:</h3>
        <p>
          Para mais detalhes, acesse: <a href="https://tecnoblog.net/responde/como-permitir-a-instalacao-de-apps-de-fontes-desconhecidas-no-android/" target="_blank" rel="noopener noreferrer">Como permitir a instalação de apps de fontes desconhecidas no Android</a>
        </p>
        <p>
          Veja também este vídeo: <a href="https://www.youtube.com/watch?v=NeBBjAVbemE" target="_blank" rel="noopener noreferrer">Habilitando Fontes Desconhecidas no Android</a>
        </p>
      </div>
      <div className="download-unknown-source-divider"></div>

       <div className="download-unknown-source-description-right">
        <h2 onClick={toggleSteps} style={{ cursor: 'pointer' }}>
          Passo a Passo para Habilitar Fontes Desconhecidas:{' '}
          <span>
            {showSteps ? (
              <svg width="20" height="20" viewBox="0 0 24 24">
                <path d="M12 8.6l6 6H6z" fill="currentColor" />
              </svg>
            ) : (
              <svg width="20" height="20" viewBox="0 0 24 24">
                <path d="M12 15.4l-6-6h12z" fill="currentColor" />
              </svg>
            )}
          </span>
        </h2>
        {showSteps && (
          <ul>
            <li>
              <strong>Passo 1:</strong> Acesse as <strong>Configurações</strong> do seu dispositivo.
            </li>
            <li>
              <strong>Passo 2:</strong> Navegue para <strong>Segurança</strong> ou <strong>Privacidade</strong>:
              <ul>
                <p></p>
                <li><strong>Xiaomi:</strong> Configurações &gt; Senhas e Segurança &gt; Privacidade.</li>
                <li><strong>Samsung:</strong> Configurações &gt; Biometria e segurança &gt; Instalar apps desconhecidos.</li>
                <li><strong>Motorola:</strong> Configurações &gt; Segurança &gt; Instalar apps desconhecidos.</li>
              </ul>
            </li>
            <li>
              <strong>Passo 3:</strong> Ative <strong>Fontes Desconhecidas</strong>:
              <ul>
                <p></p>
                <li><strong>Xiaomi:</strong> Na seção Privacidade, selecione Instalar apps desconhecidos e escolha o navegador ou o aplicativo de gerenciamento de arquivos. Em seguida, ative a opção <strong>Permitir desta fonte</strong>.</li>
                <li><strong>Samsung:</strong> Selecione o navegador, o Musga (somente se for atualização), Telegram ou outro app que será usado para o download. Habilite <strong>Permitir desta fonte</strong>.</li>
                <li><strong>Motorola:</strong> Selecione o navegador ou aplicativo de onde o APK será baixado e ative a opção <strong>Permitir desta fonte</strong>.</li>
              </ul>
            </li>
            <li>
              <strong>Passo 4:</strong> Confirme a ação. Leia atentamente o aviso de segurança e, se estiver de acordo, confirme que deseja prosseguir.
            </li>
            <li>
              <strong>Passo 5:</strong> Agora, você pode baixar e instalar o Musga diretamente do site. Clique em "Baixe já" e selecione "Android APK". Clique em "Manter Arquivo" a após o Download, clique em "Abrir arquivo".
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default DownloadUnknownSources;
