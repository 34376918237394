import React, { useState } from 'react';
import '../styles/DownloadResources.css';

const DownloadResources = () => {
  const [showApkDetails, setShowApkDetails] = useState(false);
  const [showPlayStoreDetails, setShowPlayStoreDetails] = useState(false);

  const toggleApkDetails = () => {
    setShowApkDetails(!showApkDetails);
    setShowPlayStoreDetails(false);
  };

  const togglePlayStoreDetails = () => {
    setShowPlayStoreDetails(!showPlayStoreDetails);
    setShowApkDetails(false);
  };

  return (
    <div className="download-resources-description">
      <div className="download-resources-description-left">
        <h1>Qual versão devo escolher?</h1>
        <h4>** Para baixar músicas, escolha a versão Android APK.</h4>
        <p>Ambas as versões são 100% seguras. A diferença está, portanto, nos recursos disponíveis em cada uma. A versão APK possui todos recursos liberados.</p>
  
      </div>
      <div className="download-resources-description-right">
        <ul>
          <li>
            <strong onClick={toggleApkDetails} style={{ cursor: 'pointer' }}>
              Instalação Direta (via Android APK):{' '}
              <span>
                {showApkDetails ? (
                  <svg width="20" height="20" viewBox="0 0 24 24">
                    <path d="M12 8.6l6 6H6z" fill="currentColor" />
                  </svg>
                ) : (
                  <svg width="20" height="20" viewBox="0 0 24 24">
                    <path d="M12 15.4l-6-6h12z" fill="currentColor" />
                  </svg>
                )}
              </span>
            </strong>
            {showApkDetails && (
              <div>
                <p>Permite o download de músicas e reprodução offline.</p>
                <p>
                  Permite que as músicas e playlists sejam recuperadas mesmo após
                  desinstalar o app.
                </p>
                <p>
                  Requer permissão total de armazenamento para baixar as músicas.
                </p>
                <p>Permite criar, salvar e editar playlists.</p>
                <p>Deve ser atualizado manualmente.</p>
                <p>
                  Requer ativação de fontes desconhecidas para instalar/atualizar.
                </p>
              </div>
            )}
          </li>
          <li>
            <strong onClick={togglePlayStoreDetails} style={{ cursor: 'pointer' }}>
              Instalação via Google Play:{' '}
              <span>
                {showPlayStoreDetails ? (
                  <svg width="20" height="20" viewBox="0 0 24 24">
                    <path d="M12 8.6l6 6H6z" fill="currentColor" />
                  </svg>
                ) : (
                  <svg width="20" height="20" viewBox="0 0 24 24">
                    <path d="M12 15.4l-6-6h12z" fill="currentColor" />
                  </svg>
                )}
              </span>
            </strong>
            {showPlayStoreDetails && (
              <div>
                <p>Não permite o download de músicas e reprodução offline.</p>
                <p>
                  Não permite que as músicas e playlists sejam recuperadas mesmo
                  após desinstalar o app.
                </p>
                <p>Não requer permissões de armazenamento.</p>
                <p>Permite curtir as músicas.</p>
                <p>Permite criar, salvar e editar playlists.</p>
                <p>É atualizado automaticamente pela Google Play.</p>
              </div>
            )}
          </li>
          {/*} <h4>
            * Caso queira ter a experiência completa com todas funções disponíveis, opte pela versão Android APK (requer ativação de fontes desconhecidas).
          </h4>*/}
        </ul>

      </div>
    </div>
  );
};

export default DownloadResources;