import React, { useEffect } from 'react';
import '../styles/UpdatePage.css';
import DownloadUnknownSources from '../components/DownloadUnknownSources'
import UpdateDownload from '../components/UpdateDownload'
function UpdatePage() {

  useEffect(() => {
    document.title = "Atualize - Musga Player"
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className="update-page">
      <h1>Atualize seu app!</h1>
      <UpdateDownload/>
      <DownloadUnknownSources/>

    </div>
  );
}

export default UpdatePage;
