import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { readAppCarousel } from '../data/db';
import '../styles/AppCarousel.css';
function AppCarousel() {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    return (
        <div className="AppCarousel">
            <h1> Tudo em um só app:</h1>
            {readAppCarousel().length > 0 ? (
                <Carousel responsive={responsive}>
                    {readAppCarousel().map((post, index) => (
                        <a href className="carousel-item">
                            <img src={post.banner} alt={post.title} />
                            <h3>{post.title}</h3>
                            <p>{post.description}</p>
                        </a>
                    ))}
                </Carousel>
            ) : (
                <p>Carregando...</p>
            )}
        </div>
    );
}

export default AppCarousel;