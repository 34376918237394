import appCarouselData from './appcarousel.json';


export const readAppCarousel = () => {
    return appCarouselData;
};

export const apkURL = () => {
    return 'https://files.connectifytech.com/downloads/musga';
};

