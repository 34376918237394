import React, { } from 'react';
import '../styles/DownloadBanner.css'; 

function DownloadBanner() {

  return (
    <div className="download-container">
      <div className="download-content">
        <h1>Selecione a versão que irá baixar.</h1>
        <p>Nosso app conta com duas versões diferentes. Escolha a que desejar!</p>
      </div>
      <div className="download-image-container">
        <img src="images/download-banner.png" alt="Banner" />
      </div>
    </div>
  );
}

export default DownloadBanner;
