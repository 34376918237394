import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import DownloadPage from './pages/DownloadPage';
import UpdatePage from './pages/UpdatePage';

import './App.css'

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/new" element={<HomePage/>} />
          <Route path="/download" element={<DownloadPage/>} />
          <Route path="/update" element={<UpdatePage/>} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;