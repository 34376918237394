import React, { } from 'react';
import '../styles/Footer.css';
import { Link } from 'react-router-dom';

function Footer() {


  return (
    <footer className="footer">
      <div className="footer-content">
        <p>ConnectifyTech &copy; {new Date().getFullYear()}</p>
        <p><Link style={{ color: '#FFF' }} to="https://connectifytech.com/privacy/Musga%20Player">Privacidade</Link> | <Link style={{ color: '#FFF' }} to="https://connectifytech.com/terms">Termos de Uso</Link></p>
      </div>
    </footer>
  );
}

export default Footer;
