import React from 'react';
import '../styles/DownloadDescription.css';
import { Link } from 'react-router-dom';
import { apkURL } from '../data/db';

const DownloadDescription = () => {

  return (
    <div className="download-description-container">
      <div className="download-description">
        <Link to="https://play.google.com/store/apps/details?id=app.one.musgaplayer&hl=pt_BR" className="download-button google-play-button" target="_blank" rel="noopener noreferrer">
          <img src="images/google-play.png" alt="Play Store" />
        </Link>
        <Link to={apkURL()} className="download-button" rel="noopener noreferrer">
          <img src="images/android-apk.png" alt="Arquivo APK" />
        </Link>
      </div>
    </div>
  );
};

export default DownloadDescription;