import React, { useState, useEffect } from 'react';
import '../styles/UpdateDownload.css';
import { apkURL } from '../data/db';

const UpdateDownload = () => {
  const [counter, setCounter] = useState(5);
  const [downloadStarted, setDownloadStarted] = useState(false);

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setDownloadStarted(true);
      setTimeout(() => {
        window.location.href = apkURL();
      }, 1000); // Pequeno delay para a mensagem ser visível antes do redirecionamento
    }
  }, [counter]);

  return (
    <div className="update-download">
      {downloadStarted ? (
        <p>O download começou...</p>
      ) : (
        <p>O download começará automaticamente em {counter} segundos...</p>
      )}
      <p>Se o download não começar, <a href={apkURL()}>clique aqui para baixar</a>.</p>
    </div>
  );
};

export default UpdateDownload;
