import React, { } from 'react';
import '../styles/Banner.css'; 

function Banner() {

  return (
    <div className="banner-container">
      <div className="banner-content">
        <h1>Vamos ouvir Musga?</h1>
        <p>Seu novo player de música está aqui!</p>
      </div>
      <div className="banner-image-container">
        <img src="images/banner2.png" alt="Banner" />
      </div>
    </div>
  );
}

export default Banner;