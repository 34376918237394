import React from 'react';
import '../styles/Resources.css';

const Resources = () => {
  return (
    <div className="resources-description">
      <div className="resources-description-left">
        <h1>Recursos</h1>
        <p>Cansado de procurar por horas aquela música que você ama? Com nosso app, você encontra tudo o que precisa em um só lugar.</p>
      </div>
      <div className="resources-description-right">
        <ul>
          <li>
            <strong>Sua música, sua maneira:</strong>
            <p>Com o Musga, reproduza suas músicas favoritas com a maior facilidade. Uma interface intuitiva coloca todas as funções essenciais ao seu alcance.</p>
          </li>
          <li>
            <strong>Playlists perfeitas, sempre:</strong>
            <p>Crie e personalize suas playlists em segundos. Adicione, remova e organize suas músicas favoritas como quiser.</p>
          </li>
          <li>
            <strong>Ouça suas músicas onde quiser:</strong>
            <p>Baixe suas músicas favoritas em um piscar de olhos e desfrute de sua playlist offline, sem se preocupar com a internet.</p>
          </li>
          <li>
            <strong>Tudo organizado:</strong>
            <p>Encontre suas músicas com facilidade. Filtre por data, nome ou artista e tenha acesso rápido às suas músicas mais ouvidas e baixadas.</p>
          </li>
          <li>
            <strong>Um mundo de música ao seu alcance:</strong>
            <p>Com nossa API de músicas, você tem acesso a um vasto catálogo de músicas, garantindo que você nunca ficará sem novas descobertas.</p>
          </li>

        </ul>
      </div>
    </div>
  );
};

export default Resources;
