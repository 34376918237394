import React from 'react';
import '../styles/Description.css';
import { Link } from 'react-router-dom';

const Description = () => {
  return (
    <div className="home-description">
      <div className="home-description-left">
        <img src="images/mulher2.png" alt="image-alt" />
      </div>
      <div className="home-description-right">
        <h2>A nova maneira de escutar música chegou!</h2>
        <p>Cansado de apps de música complicados? O Musga te oferece uma experiência pura e intuitiva, com tudo o que você precisa para curtir suas playlists favoritas. Simples, eficiente e feito para você.</p> 
          <Link to="/download" className="download-home-button">Baixe Já!</Link>
      </div>
    </div>
  );
};

export default Description;
